.sub-card {
  background-color: rgb(255, 255, 255) !important;
  margin-top: 70px;
  margin-left: 40px !important;

  border-width: 4px;
  box-shadow: 3px 2px 10px #3b3d3c88;
  max-width: 85%;
  max-height: 30%;
  height: 100%;
  border-radius: 20px;
}

@media (max-width: 650px) {
  .sub-card {
    max-width: 80%;
  }

  .grid-deck {
    max-width: 100%;
    height: 100%;
    flex-direction: column;
  }
}

.sub-card-container {
}

.sub-card-title {
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: 700;
  font-size: 22px;
}

.sub-card-img {
  object-fit: contain;
  height: 300px !important;
  max-width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.badge {
  box-shadow: none !important;
}
.badge h4 {
  padding-bottom: 0px !important;
  margin-bottom: 5px !important;
  font-size: 1.3rem;
  font-weight: 600;
  padding-right: 10px;
  padding-left: 10px;
  color: rgb(255, 255, 255);
}

.badge-pill {
}

.danger-pill {
  background-color: rgb(248, 94, 94) !important;
}

.success-pill {
  background-color: rgb(78, 179, 78) !important;
}

.more-info-btn {
  background-color: #ffffff;
  border-radius: 12px;
  color: black;
  border-color: rgb(34, 33, 33);
  border-width: 3px;
  box-shadow: 1px 2.3px 10px 0.2px #979797;
}

.more-info-btn:hover {
  background-color: rgba(43, 41, 41, 0.788);
  color: white;
  border-color: rgb(43, 41, 41);
}

.card-details {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.13);
}

.modal-image {
}

.modal-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-modal {
  border-radius: 20px;
}

.modal-content {
  border-radius: 50px;
}

.modal-title {
  width: 100%;
  text-transform: capitalize;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.modal-details-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #131313;
}

.modal-content {
  border-color: seagreen;
  border-width: 7px;
  box-shadow: 10px 10px 12px rgba(83, 80, 80, 0.068);
}

@media (max-width: 575px) {
}

.card-info-container {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}
