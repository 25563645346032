.email-btn {
  background-color: rgb(61, 146, 72);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.473);
}

.email-btn:hover {
  background-color: rgb(77, 185, 92);
}

h3 {
  font-family: "Poppins", sans-serif;
}

.tabs {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0) !important;
}

.nav-tabs {
  border-radius: 0px;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  background-color: rgba(59, 145, 66, 0.295);
}
.nav-tabs a:active {
  color: rgb(55, 156, 55);
}

.nav-item {
  border-radius: 0px;
}
.nav-link {
}

.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

.nav-tabs .nav-link {
  width: 50%;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  color: rgb(255, 255, 255);
  text-shadow: 3px 1px 10px rgba(189, 184, 184, 0.288);
  font-size: 25px;
  text-align: center;
}
