* {
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.568);
}

.grid-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: row wrap;
}

.content-container {
}

.newsletter-margin {
  margin-top: 200px;
  margin-bottom: 200px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-buffer-sm {
  margin-top: 50px;
}

.sub-title {
  font-family: "Mulish", sans-serif !important;
  font-size: 25px;
  padding-bottom: 15px;
}
