.secondary {
  background-color: rgb(255, 255, 255);
  color: #34af6b;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 13px;
  cursor: pointer;
  border-color: #34af6b;
  border-width: 4px;
  border-style: solid;
  height: 70%;
}

.secondary a {
  color: #34af6b;
}

.secondary:hover {
  background-color: #76bb959f;
  box-shadow: 2px 4px 20px rgba(112, 128, 144, 0.705);
}

.secondary:hover a {
  color: white;
}

.primary {
  background-color: #34af6b;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 2px 10px rgba(112, 128, 144, 0.596);
  border-radius: 13px;
  cursor: pointer;
  border-color: #34af6b;
  border-width: 4px;
  border-style: solid;
}

.sm {
  height: 60px;
  width: 120px;
}

.md {
  height: 60px;
  width: 170px;
}

.btn1-title {
  font-size: 24px;
  font-weight: 800;
}

.btn1:hover a {
  text-decoration: none !important;
}

.btn1 a:hover {
  color: white;
}
