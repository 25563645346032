.newsletter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.newsletter-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter-title h1 {
  font-family: "Big Shoulders Inline Text", cursive !important;
  font-weight: 900;
  font-size: 75px;
  text-align: center;
  color: aliceblue;
}

.newsletter-title h2 {
  font-family: "Poppins", sans-serif !important;
  font-size: 45px;
  font-weight: lighter !important;
  color: rgb(0, 0, 0);
}

h2.newsletter-input-title {
  font-family: "Poppins", sans-serif !important;
  text-align: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-input {
  width: 300px;

  padding-bottom: 15px;
}

.sub-btn {
  border-style: none;
  border: 0.2em solid #000000;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.4em;
  box-sizing: border-box;
  text-decoration: none;
  background-color: #2fa364;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.342);
  width: 200px;
  transition: all 0.4s;
}
.sub-btn:hover {
  background-color: #248f54;
}

.sub-btn h4 {
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.sub-btn:hover h4 {
  color: rgb(255, 255, 255);
}

.sub-btn-container {
  padding-top: 10px;
}

.form-control {
  width: 100%;
  font-family: "Poppins";
}

.form-control:focus {
  border-color: rgb(0, 0, 0);
  border-width: 3px;
  outline: none;
}

.newsletter-input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
