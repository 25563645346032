.navbar {
  background-color: #34af6b;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.486);
  padding: 0px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  font-family: "Mulish", sans-serif !important;
  font-weight: 700;
  font-size: 40px;
  color: white;
  text-align: center;
  margin-top: 10px;
}
